import { TextField } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { URLS } from 'constants/endpoints';

import chatStyles from './chat.module.css';
import chatbot from 'assets/images/solutions/chatBot.svg';
import sendBtn from 'assets/images/solutions/sendBtn.svg';
import useGenAiAxios from 'utils/axiosGenAi';
import { Grid } from '@mui/material';

// Define types for the chat data
interface ChatContent {
  message: string;
  url?: string;
}

interface ChatMessage {
  isUser: 'TRUE' | 'FALSE';
  content: ChatContent;
}

const StandAlone = ({
  questions,
  handleDropdownChange,
  selectedValue,
  setAccordionData,
  setFormData,
  checked,
  selectedSub,
  setQuestions,
  getUUID
}: {
  questions: any[];
  handleDropdownChange: (prompt: string) => void;
  selectedValue: any;
  setAccordionData: any;
  setFormData: any;
  checked: string;
  selectedSub: string;
  setQuestions: any;
  getUUID: any;
}) => {
  const [oldChat, setOldChat] = useState<any>([]);
  const [newChat, setNewChat] = useState<ChatMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const axiosService = useGenAiAxios();
  const template = useMemo(() => {
    if (checked === 'Care Plan')
      return {
        'Patient Authorization': {
          patientID: '',
          name: '',
          age: ''
        },
        'Contact Info': {
          Phone: '',
          Email: ''
        },
        'Current Diagnosis': {
          Diagnosis: '',
          'Date Diagnosed': ''
        },
        'Current Medications': {
          Medication: '',
          Dosage: '',
          Frequency: ''
        },
        'Follow Up Appointment': {
          Date: '',
          Time: '',
          Location: ''
        },
        'Primary Care Physician': {
          Name: '',
          Contact: '',
          Email: ''
        }
      };
    else if (checked === 'Personalized-Loan Application')
      return {
        'Loan Application Details': {
          'Applicant Name': '',
          'Date of Birth': '',
          'Marital Status': '',
          'Employment Status': ''
        },
        'Financial Information': {
          'Annual Income': '',
          'Existing Loans': [''],
          'Monthly Expenses': ''
        },
        'Loan Request Details': {
          'Loan Type': '',
          'Loan Amount': '',
          'Loan Term': '',
          'Interest Rate': ''
        },
        'Contact Information': {
          'Telephone Number': '',
          'Email ID': '',
          'Current Address': ''
        },
        'Credit History': {
          'Credit Score': '',
          'Credit Report Date': ''
        },
        'Loan Application Status': {
          Status: '',
          ETA: '',
          Description: ''
        }
      };
    else if (checked === 'Personalized-Account Opening')
      return {
        'Account Opening': {
          'First Name': '',
          'Middle Name': '',
          'Last Name': '',
          'Date of Birth': ''
        },
        'Identification Details': {
          'Pan Number': ''
        },
        'Contact Information': {
          'Present Address': '',
          'Telephone Number': '',
          'Email ID': ''
        },
        'Account Specifications': {
          'Bank Name': '',
          'Type of Account': '',
          'Initial Deposit': ''
        },
        'KYC Details': {
          'Proof of Identification': '',
          'Address Proof': ''
        },
        'New Account Opening Status': {
          Status: '',
          ETA: '',
          Description: ''
        }
      };
    else if (checked === 'Claim Checker')
      return {
        'Customer Authorization': {
          'Customer ID': '',
          'Authorization Status': ''
        },
        'Customer Summary': {
          'Policy Number': '',
          Active: '',
          'Outstanding Claim': ''
        },
        'Claim Details': {
          'Claim Form': '',
          Amount: '',
          Status: '',
          ETA: '',
          Description: ''
        },
        'Policy Information': {
          'Policy Type': '',
          'Issuance Date': '',
          'Expiration Date': '',
          Premium: ''
        }
      };
    else if (checked === 'Treatment Assist')
      return {
        'Patient Authorization': {
          Name: '',
          DOB: '',
          'Consent Access': '',
          Age: '',
          Symptoms: '',
          'Premedication Details': '',
          'Allergic Conditions': ''
        },
        'Appointment Booking Details': {
          "Doctor's Name": '',
          Department: '',
          'Scheduled Date': '',
          'Scheduled Time': '',
          'Hospital Name': ''
        }
      };
    else if (checked === 'New Insurance' && selectedSub === 'Life Insurance')
      return {
        'Policy Details': {
          'Insured Name': '',
          'Policy Number': '',
          'Policy Issuance Date': '',
          'Term of Policy': ''
        },
        'Financial Details': {
          'Sum Assured': '',
          'Premium Payment Term': '',
          'Premium Amount': '',
          'Payment Frequency': ''
        },
        'Beneficiary Details': {
          'Primary Beneficiary': '',
          'Relationship to Insured': '',
          'Contingent Beneficiary': ''
        },
        Riders: {
          'Accidental Death Benefit': '',
          'Critical Illness Rider': '',
          'Disability Rider': ''
        }
      };
    else if (checked === 'New Insurance' && selectedSub === 'Health Insurance')
      return {
        'Policy Details': {
          'Insured Name': '',
          'Policy Number': '',
          'Policy Issuance Date': '',
          'Period of Insurance': '',
          'Coverage Type': ''
        },
        'Coverage Details': {
          'Sum Insured': '',
          Deductible: '',
          'Premium Amount': ''
        },
        'Benefit Details': {
          'Room Rent Limit': '',
          'Maternity Cover': '',
          'Pre-existing Disease Cover': '',
          'Outpatient Department (OPD) Expenses': ''
        }
      };
    else if (checked === 'New Insurance' && selectedSub === 'Vehicle Insurance')
      return {
        'Policy Details': {
          'Insured Name': '',
          'Policy Number': '',
          'Policy Issuance Date': '',
          'Period of Insurance': ''
        },
        'Vehicle Details': {
          'Registration Number': '',
          'Make/Model': '',
          'Registration Year': '',
          'Engine Number': '',
          'Chassis Number': ''
        },
        'Premium Details': {
          'Total Premium': '',
          'Own Damage Premium': '',
          'Liability Premium': '',
          'Insurance Goods and Services Tax (IGST)': ''
        }
      };
    else if (selectedSub === 'Employee Assist')
      return {
        'Case Status': '',
        'Employee Authorization': {
          'Employee ID': '',
          'Access Level': ''
        },
        'My Customer Information': {
          'Customer Name': '',
          Email: '',
          Phone: '',
          'Account Status': ''
        },
        'Compliance with SEBI/RBI': {
          'Compliance Status': '',
          'Last Audit Date': '',
          Notes: ''
        }
      };
    else return {};
  }, [checked, selectedValue, selectedSub]);
  const flowId = 'd6cd4ff7-1953-4ccb-999b-058a1e6f9331';
  const convertDataFormat = (data: any): any => {
    return data.map((message: { role: string; content: any }) => ({
      isUser: message.role === 'userMessage' ? 'TRUE' : 'FALSE',
      content: {
        message: message.content
      }
    }));
  };

  useEffect(() => {
    setNewChat([]);
  }, [selectedValue, selectedSub, checked]);

  useEffect(() => {
    chatContainerRef.current?.scrollIntoView();
  }, [oldChat, newChat]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchChat = async () => {
    const uuid = getUUID(selectedValue, selectedSub, checked);
    const res = await axios.get(`https://flowise.demo.discovercloud.io/api/v1/chatmessage/${flowId}?chatId=${uuid}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer S5b1oDYg0NnM8QwN8IPWAPeNmSdjlvJJO6JThkVAy0s'
      }
    });
    setOldChat(convertDataFormat(res.data));
    const params = selectedSub
      ? {
          category: selectedSub.split(' ').join('-'),
          sub_category: checked.split(' ').join('-')
        }
      : {};
    const ques = await axiosService.get(URLS.GEN_AI.STANDALONE_QA + '/' + selectedValue.split(' ').join('-'), {
      params
    });
    setQuestions(ques.data.data);
    setNewChat([]);
    setAccordionData([]);
    setFormData({});
  };

  useEffect(() => {
    fetchChat();
  }, [selectedValue, selectedSub, checked]);

  const validationSchema = yup.object({
    prompt: yup.string().required('Please enter a message')
  });

  const extractUrl = (text: string): string | null => {
    const urlPattern = /https?:\/\/[^\s]+/g;
    const match = text.match(urlPattern);
    return match ? match[0] : null;
  };

  const handleChatSubmission = async (values: { prompt: string }, { resetForm }: { resetForm: () => void }) => {
    handleDropdownChange(values.prompt);
    setLoading(true);
    const url = extractUrl(values.prompt);
    const newUserMessage: ChatMessage = { isUser: 'TRUE', content: { message: values.prompt, url: url || undefined } };
    setNewChat((currentData) => [...currentData, newUserMessage]);
    resetForm();
    const uuid = getUUID(selectedValue, selectedSub, checked);

    try {
      const response = await axios.post(
        `https://flowise.demo.discovercloud.io/api/v1/prediction/${flowId}`,
        { chatId: uuid, question: values.prompt },
        { headers: { 'Content-Type': 'application/json', Authorization: 'Bearer S5b1oDYg0NnM8QwN8IPWAPeNmSdjlvJJO6JThkVAy0s' } }
      );
      const botMessage = response.data.text;
      const botUrl = extractUrl(botMessage);
      const botResponse: ChatMessage = { isUser: 'FALSE', content: { message: botMessage, url: botUrl || undefined } };

      if (checked === 'Personalized-Account Opening') {
        const template = {
          'First Name': '',
          'Last Name': '',
          'Date of Birth': '',
          'Present Address': '',
          'Mobile Number': '',
          'Bank Name': '',
          'Type of Account': ''
        };
        const tempRes = await axiosService.post(URLS.GEN_AI.FILL_TEMPLATE, {
          input_temp: [...newChat, botResponse],
          output_temp: { formData: template }
        });
        if (tempRes.data.data.formData) setFormData(tempRes.data.data.formData);
      }
      if (checked === 'Personalized-Loan Application') {
        const template = {
          'Insured Name': '',
          'Registration Number': '',
          'Make/Model': '',
          'Registration Year': '',
          'Engine Number': '',
          'Chassis Number': '',
          'Total Premium': '',
          'Insurance Goods and Services Tax (IGST)': ''
        };
        const tempRes = await axiosService.post(URLS.GEN_AI.FILL_TEMPLATE, {
          input_temp: [...newChat, botResponse],
          output_temp: { formData: template }
        });
        if (tempRes.data.data.formData) setFormData(tempRes.data.data.formData);
      }
      setNewChat((currentData) => [...currentData, botResponse]);
      const tempRes = await axiosService.post(URLS.GEN_AI.FILL_TEMPLATE, {
        input_temp: [...newChat, botResponse],
        output_temp: template ? template : {}
      });
      setAccordionData(tempRes.data.data);
    } catch (error) {
      const errorResponse: ChatMessage = {
        isUser: 'FALSE',
        content: { message: 'Sorry I am unable to process your query, can you try in a different way?' }
      };
      setNewChat((currentData) => [...currentData, errorResponse]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={chatStyles.chat}>
      <div className={chatStyles.chatContainer}>
        <div className={chatStyles.chatHeader}>
          <img src={chatbot} alt="Chatbot" />
          <p>Chatbot</p>
        </div>
        <Formik initialValues={{ prompt: '' }} validationSchema={validationSchema} onSubmit={handleChatSubmission}>
          {({ errors, touched, setFieldValue, resetForm }) => (
            <Form>
              <div className={chatStyles.chatBody}>
                <Grid container spacing={2}>
                  {questions.map((question, index) => (
                    <Grid xs={12} md={6} key={index}>
                      <div
                        className={chatStyles.prefillQues}
                        onClick={() => {
                          setFieldValue('prompt', question);
                        }}
                      >
                        {question}
                      </div>
                    </Grid>
                  ))}
                </Grid>
                {[...oldChat, ...newChat].map((chatItem, index) => (
                  <div key={index} className={chatItem.isUser === 'TRUE' ? chatStyles.userMsgContainer : chatStyles.botMsgContainer}>
                    <p className={chatItem.isUser === 'TRUE' ? chatStyles.userMsg : chatStyles.botMsg}>
                      {chatItem.content.message}
                      {chatItem.content.url && (
                        <a href={chatItem.content.url} target="_blank" rel="noopener noreferrer">
                          Link
                        </a>
                      )}
                    </p>
                  </div>
                ))}
                {loading && (
                  <div className="typingIndicatorContainer">
                    <div className="typingIndicatorBubble">
                      <div className="typingIndicatorBubbleDot"></div>
                      <div className="typingIndicatorBubbleDot"></div>
                      <div className="typingIndicatorBubbleDot"></div>
                    </div>
                  </div>
                )}
                <div ref={chatContainerRef} />
              </div>

              <div className={chatStyles.chatFooter}>
                <Field
                  as={TextField}
                  label="Enter Message"
                  fullWidth
                  name="prompt"
                  error={touched.prompt && !!errors.prompt}
                  helperText={touched.prompt && errors.prompt}
                />
                <button className={chatStyles.sendBtn} type="submit" disabled={loading}>
                  <img src={sendBtn} alt="Send Button" height={20} />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default StandAlone;
